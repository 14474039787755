<template>
    <div class="heavy-shelves">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Estantería Pesada',
		meta: [
			// Ummami Express
			{ name: 'description', content:'fabricantes de estantería metálica para sistemas de almacenamiento en la ciudad de bucaramanga, barranquilla, valledupar, buenaventura, cucuta' },
			{ name: 'keywords', content:'estantería pesada, estantería metálica pesada, rack de almacenamiento, carga selectiva, rack selectivo, montacargas, estanterías para palletsestanteria metalica, estanterias metalicas, estanteria metalica bucaramanga, estanteria metalica barranquilla, estanteria pesada, estanteria liviana, fabrica de estanterias metalicas, racks de almacenamiento, estanterias metalicas en bucaramanga, estanterias metalicas en barranquilla' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Estantería Pesada'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'fabricantes de estantería metálica para sistemas de almacenamiento en la ciudad de bucaramanga, barranquilla, valledupar, buenaventura, cucuta'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Estantería Pesada'},
			{name: 'twitter:description', content: 'fabricantes de estantería metálica para sistemas de almacenamiento en la ciudad de bucaramanga, barranquilla, valledupar, buenaventura, cucuta' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Estantería Pesada'},
			{itemprop: 'description', content: 'fabricantes de estantería metálica para sistemas de almacenamiento en la ciudad de bucaramanga, barranquilla, valledupar, buenaventura, cucuta' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    metaInfo() {
        return {
            title: this.page_title,
        }
    },
    data() {
        return {
            page_title: 'Estantería Pesada',
            data_products: {
                title_page: 'Estantería pesada',
                types: [
                    // { text: 'Todos', value: 'todos' },
                    { text: 'Rack selectivo', value: 'rack-selectivo' },
                    { text: 'Dirve in', value: 'carga-compacta-drive-in' },
                    { text: 'Cantilever', value: 'carga-larga-o-cantilever' },
                    { text: 'Carga con estibas', value: 'carga-con-estibas' },
                    { text: 'Carretes o Porta-Bobinas', value: 'carretes-o-porta-bobinas' },
                    { text: 'Dinámicas o de flujo', value: 'dinamicas-o-de-flujo' },
                    { text: 'Autoportantes y de Campo', value: 'autoportantes-y-de-campo' }
                ],
                products: [
                    {
                        name: 'Rack selectivo',
                        description: `
                            <p>
                                Adecuada para el control del stock, se acomoda a cualquier
                                dimensión, volumen o peso y se puede combinar con estanterías para picking manual.
                                Considerada como el sistema global para el ingreso inmediato y unitario a cada estiba es
                                la solución idónea para las bodegas en las que se hace necesario guardar productos con
                                diversidad de referencias.
                            </p>
                            <p>
                                El nivel, altura y distribución de las estanterías, se delimita de acuerdo a los atributos del
                                montacargas, los productos que serán almacenados y de la capacidad del lugar.
                            </p>
                            <ul class="pl-3">
                                <li class="mb-2">El diseño de la estantería selectiva es adecuado para el control de la mercancía.</li>
                                <li class="mb-2">Proporciona la adaptación de escaleras para el almacenamiento de productos de elevadas cantidades.</li>
                                <li class="mb-2">La viga proporciona mayor amplitud de carga e impide que se deforme la estructura.</li>
                                <li class="mb-2">El gancho evita que un choque traslade las vigas de su alojamiento.</li>
                                <li class="mb-2">Las torres se sitúan en el suelo a través de zapatas.</li>
                                <li class="mb-2">De acuerdo a las características de los productos, se sitúa la malla en el fondo de la estantería para 
                                    evitar el derrumbe de los productos.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_1.jpg',
                        slides: [
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_1.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_2.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_3.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_4.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_5.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_6.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_7.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_8.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_9.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_10.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_11.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_12.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_13.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_14.jpg',
                            'estanterias-metalicas/pesada/productos/rack-selectivo/selectivo_15.jpg'
                        ],
                        price: '',
                        tags: ['rack-selectivo']
                    },
                    {
                        name: 'Drive in',
                        description: `
                            <p>
                                Se trata de un método de almacenamiento compacto el cuál se caracteriza por optimizar
                                el lugar de almacenamiento, beneficiando hasta un 85% el área libre.
                            </p>
                            <p>
                                Proporciona el máximo de altura y espacio del lugar debido a la disminución de pasillos de
                                labores a realizar. Se acomoda a las necesidades, dimensiones, espacios y peso de los
                                artículos. Es ideal para el almacenamiento de los productos con referencias iguales,
                                ingresos y salidas reguladas, razón por la cual se tiene dominio y se trata de una
                                estantería de elevada consistencia.
                            </p>
                            <ul class="pl-3">
                                <li class="mb-2">Ahorro del espacio pues solo se requiere de un pasillo para la circulación de la mercancía</li>
                                <li class="mb-2">Este procedimiento de almacenamiento drive-in compacto es el menos costoso entre los existentes.</li>
                                <li class="mb-2">El método de drive-in es compatible con otras técnicas de almacenaje.</li>
                                <li class="mb-2">Permite una pronta modificación de la estructura de acuerdo a las necesidades del lugar, el montaje de su estructura es fácil de realizar.</li>
                                <li class="mb-2">Este método de almacenamiento está creado para que se use con embalajes de poca rotación, numerosos artículos y referencias.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/pesada/productos/drive-in/drive_in_6.jpg',
                        slides: [
                            'estanterias-metalicas/pesada/productos/drive-in/drive_in_1.jpg',
                            'estanterias-metalicas/pesada/productos/drive-in/drive_in_2.jpg',
                            'estanterias-metalicas/pesada/productos/drive-in/drive_in_3.jpg',
                            'estanterias-metalicas/pesada/productos/drive-in/drive_in_4.jpg',
                            'estanterias-metalicas/pesada/productos/drive-in/drive_in_5.jpg',
                            'estanterias-metalicas/pesada/productos/drive-in/drive_in_6.jpg',
                            'estanterias-metalicas/pesada/productos/drive-in/drive_in_7.jpg',
                            'estanterias-metalicas/pesada/productos/drive-in/drive_in_8.jpg',
                            'estanterias-metalicas/pesada/productos/drive-in/drive_in_9.jpg'
                        ],
                        price: '',
                        tags: ['carga-compacta-drive-in']
                    },
                    {
                        name: 'Cantilever',
                        description: `
                            <p>
                                Proyectada para el almacenamiento de elementos de carga de gran longitud, volúmenes o
                                dimensiones diversas, por ejemplo: tubos, varillas, puertas, muebles, material plástico,
                                madera, etc. Por lo tanto, permite versatilidad al adaptarse a las diversas necesidades.
                            </p>
                            <p>
                                Permite la variabilidad entre los diversos volúmenes del embalaje de acuerdo a la entrada
                                directa de la carga y la regulación de la elevación de los distintos niveles. El manejo de la
                                mercancía puede ejecutarse de manera manual.
                            </p>
                            <p>VENTAJAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Este sistema se puede desmontar y sus niveles de carga son fáciles de regular.</li>
                                <li class="mb-2">Permite diseñar la estantería a una cara llamada simple o a dos caras denominada doble.</li>
                                <li class="mb-2">El número de niveles se adecua de acuerdo a la necesidad de la carga.</li>
                                <li class="mb-2">Genera un almacenamiento perfecto de las cargas largas y acceso directo a la mercancía</li>
                                <li class="mb-2">Se puede almacenar cargas de diferentes longitudes, peso y fondo.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/pesada/productos/cantilever/cantilever_3.jpg',
                        slides: [
                            'estanterias-metalicas/pesada/productos/cantilever/cantilever_1.jpg',
                            'estanterias-metalicas/pesada/productos/cantilever/cantilever_2.jpg',
                            'estanterias-metalicas/pesada/productos/cantilever/cantilever_3.jpg'
                        ],
                        price: '',
                        tags: ['carga-larga-o-cantilever']
                    },
                    {
                        name: 'Carga con estibas',
                        description: `
                            <p>
                                Se trata de un sistema que sirve para
                                almacenar cargas pesada en estibas con ayuda de montacargas.
                                Permite el acceso al 100 % de las posiciones del producto, ofreciendo
                                control y disponibilidad sin necesidad de mover ninguna otra carga para
                                acceder a la estiba que se requiera. Del mismo modo es de doble
                                profundidad aumentando la capacidad de estibas almacenadas en bodega.   
                            </p>
                            <p>
                                Al momento de instalar se adecua organizando pasillos, teniendo en cuenta
                                el ancho de montacargas, carretillas, apilador o elevadores. Igualmente, a
                                la altura del edificio ya que define la capacidad de elevación.
                            </p>
                            <p>CARACTERÍSTICAS </p>
                            <ul class="pl-3">
                                <li class="mb-2">Fácil control de stocks, ya que cada hueco pertenece a una estiba.</li>
                                <li class="mb-2">Adaptabilidad a cualquier tipo de carga tanto por peso como por volumen.</li>
                                <li class="mb-2">Ideal para almacenamiento de productos con gran variedad de referencias.</li>
                                <li class="mb-2">Acceso directo a cada estiba almacenada.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/pesada/productos/carga-con-estibas/estibas_2.jpg',
                        slides: [
                            'estanterias-metalicas/pesada/productos/carga-con-estibas/estibas_1.jpg',
                            'estanterias-metalicas/pesada/productos/carga-con-estibas/estibas_2.jpg',
                            'estanterias-metalicas/pesada/productos/carga-con-estibas/estibas_3.jpg'
                        ],
                        price: '',
                        tags: ['carga-con-estibas']
                    },
                    {
                        name: 'Carretes o Porta-bobinas',
                        description: `
                            <p>
                                Están especialmente diseñadas para almacenar bobinas y carretes de diferentes pesos y
                                tamaño. Al mismo tiempo se adapta para preparar pedidos desde su
                                estructura. Gracias a su distribución ordenada brinda una flexibilidad de uso
                                y manipulación, ya que permite regular los niveles y hacer cambios de
                                ubicación de forma simple.
                            </p>
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Permite acomodar bobinas y carretes de diferentes tamaños y pesos.</li>
                                <li class="mb-2">Permite llevar a cabo técnicas de preparación de pedidos desde la estructura.</li>
                                <li class="mb-2">Cuenta con un sistema de regulación sencillo.</li>
                                <li class="mb-2"></li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/pesada/productos/carretes-o-porta-bobinas/carrete_2.jpg',
                        slides: [
                            'estanterias-metalicas/pesada/productos/carretes-o-porta-bobinas/carrete_1.jpg',
                            'estanterias-metalicas/pesada/productos/carretes-o-porta-bobinas/carrete_2.jpg',
                            'estanterias-metalicas/pesada/productos/carretes-o-porta-bobinas/carrete_3.jpg'
                        ],
                        price: '',
                        tags: ['carretes-o-porta-bobinas']
                    },
                    {
                        name: 'Dinámicas o de flujo',
                        description: `
                            <p>
                                Se trata de una estantería de
                                almacenamiento por gravedad y está diseñad para productos de alta
                                rotación. Se compone de dos elementos importantes: una parte estática
                                formada por los mismos elementos de estantería convencional y una parte
                                móvil con ruedas o rodillos para que la mercancía se deslice sobre ella.
                            </p>
                            <p>
                                Su rotación es sencilla, la estantería se alimenta por la parte posterior y se
                                retira la mercancía por la parte frontal. Los productos se deslizan por
                                gravedad gracias a la inclinación.
                            </p>
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Permite trabajar bajo el sistema de inventarios FIFO.</li>
                                <li class="mb-2">Ideal para productos con alta rotación.</li>
                                <li class="mb-2">Permite una mayor rapidez en la preparación de pedidos.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/pesada/productos/dinamicas-o-de-flujo/dinamica_1.jpg',
                        slides: [
                            'estanterias-metalicas/pesada/productos/dinamicas-o-de-flujo/dinamica_1.jpg',
                            'estanterias-metalicas/pesada/productos/dinamicas-o-de-flujo/dinamica_2.jpg',
                            'estanterias-metalicas/pesada/productos/dinamicas-o-de-flujo/dinamica_3.jpg'
                        ],
                        price: '',
                        tags: ['dinamicas-o-de-flujo']
                    },
                    {
                        name: 'Autoportantes y de Campo',
                        description: `
                            <p>
                                Es un tipo de estantería que está
                                diseñada para crear bodegas completas. Son fáciles y rápidas de armar y
                                cuentan con una total seguridad. Ideales para cualquier tipo de bodegas
                                incluidas incluyendo cámaras frigoríficas. Para su construcción solo se
                                necesita un piso de concreto; las paredes y el techo son sostenidas por la
                                estructura con total seguridad.
                            </p> 
                        `,
                        url_image: 'estanterias-metalicas/pesada/productos/autoportantes/autoportante_1.jpg',
                        slides: [
                            'estanterias-metalicas/pesada/productos/autoportantes/autoportante_2.jpg',
                            'estanterias-metalicas/pesada/productos/autoportantes/autoportante_3.jpg',
                            'estanterias-metalicas/pesada/productos/autoportantes/autoportante_1.jpg'
                        ],
                        price: '',
                        tags: ['autoportantes-y-de-campo']
                    }
                ],
            }
        }
    },
   computed: {
        url_tag() {
            return this.$route.params.tag;
        }
    },
    watch: {
        url_tag() {
            this.page_title = this.url_tag.replaceAll('-', ' ').capitalize();
        }
    }
}
</script>